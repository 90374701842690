import { useEffect } from 'react'
import { useForm, type FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useUpdatePropertyBindingMappingCondition } from '~/hooks/api/codeGenerationStrategy/useUpdatePropertyBindingMappingCondition'
import { MappingConditionFormEnum } from '~/models/enums/forms/MappingConditionFormEnum'
import type { CodeGenerationStrategyDialogProps } from '~/models/types/components/codeGenerationStrategy/CodeGenerationStrategyDialogProps'
import { FormHook } from '~/models/types/hooks/FormHook'
import { QueryMutationHook } from '~/models/types/hooks/QueryMutationHook'

type UseDialogMappingConditionHook = FormHook & QueryMutationHook

/**
 * Hook to support the `DialogMappingCondition` component.
 */
export const useDialogMappingCondition = (
  props: CodeGenerationStrategyDialogProps,
): UseDialogMappingConditionHook => {
  const { fileId, handleCloseDialog, isDialogOpen, propertyBinding } = props

  let { generationStrategyId } = useParams()

  // Form set up.
  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FieldValues>({
    values: {
      [MappingConditionFormEnum.MAPPING_CONDITION]:
        propertyBinding?.mappingCondition || '',
    },
  })

  // Form submit mutation.
  const {
    error,
    isPending,
    isSuccess,
    mutate,
    reset: resetMutation,
  } = useUpdatePropertyBindingMappingCondition({
    generationStrategyId,
    fileId,
    propertyBindingId: propertyBinding.id,
  })

  // Reset form states when the dialog is closed.
  useEffect(() => {
    if (!isDialogOpen) {
      resetForm?.()
      resetMutation?.()
    }
  }, [isDialogOpen])

  // Close dialog when mutation is success.
  useEffect(() => {
    if (isSuccess) handleCloseDialog()
  }, [isSuccess])

  // Handles the form submit.
  const handleFormSubmit = (data: FieldValues) => {
    mutate(data)
  }

  return {
    control,
    error,
    handleFormSubmit,
    handleSubmit,
    isPending,
  }
}
